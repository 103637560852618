import {all, call, put, takeLatest} from 'redux-saga/effects'
import LeaderBoardSlice from "../reducers/leader-board.reducer";
import {PayloadAction} from "@reduxjs/toolkit";
import LeaderBoardService from "../services/leader-board.service";

const {
  getLeaderBoard,
  getLeaderBoardSuccess,
  getLeaderBoardFailed
} = LeaderBoardSlice.actions

function* getLeaderBoardSaga(action: PayloadAction) {
  try {
    const data: Promise<any> = yield call(LeaderBoardService.getLeaderBoard, action.payload)
    yield put(getLeaderBoardSuccess(data))
  }catch (e) {
    yield put(getLeaderBoardFailed(e))
  }
}

export default function* root(){
  yield all([
    takeLatest(getLeaderBoard, getLeaderBoardSaga)
  ])
}