import FirebaseService from "./firebase.service";

const LeaderBoardService = {
  getLeaderBoard: (payload: any) => {
    return [{
      id: 1,
      position: 1,
      name: 'eatsleepcode',
      winRatePercent: 90
    }]
  }
}

export default LeaderBoardService
